import type { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";

import { config } from "shared/data";
import { logout } from "shared/lib/account";

import type {
  FollowPayload,
  FollowPayloadMessageItem,
  GetUserFollowList,
  UserFollowList,
} from "./types";
import { generateNewList } from "./utils";

export const axiosOptions: AxiosRequestConfig = {
  withCredentials: true,
};

export const fetchList = async () => {
  const url = new URL(`${config.general.userFollowHost}/user-follow-list-v2`);
  try {
    const resp = await axios.get<GetUserFollowList>(url.toString(), {
      ...axiosOptions,
      params: {
        detail: false,
      },
    });
    return resp?.data?.userFollowList;
  } catch {
    return { authors: [], sections: [], topics: [] } as UserFollowList;
  }
};

export const create = async (
  previousFollowList: GetUserFollowList["userFollowList"] | undefined,
  payload: FollowPayload,
) => {
  const url = new URL(`${config.general.userFollowHost}/follow`);

  const messageItem: FollowPayloadMessageItem = {
    ...payload,
    type: "sections_topics_authors",
  };

  try {
    await axios.post(
      url.toString(),
      {
        messageItem,
      },
      axiosOptions,
    );
  } catch (error) {
    const { response } = error as Required<AxiosError>;
    if (response?.status === 401) {
      logout();
    }
  }
  return generateNewList(previousFollowList, payload);
};
